var filters = {
  todate: function (value) {
    var a = value.substring(0, 10)
    return a // 时间前10位
  },
  getTwo: function (value) {
    var a = value.substring(0, 2)
    return a // 字符串前两位
  },
  getNumber: function (value) {
    var a = parseInt(value)
    return a // 字符串前两位
  },
  replaceStr: function (value) {
    var a = value.replace('现场管理人员', '')
    return a // 字符串前两位
  },
  getcompanyName: function (st) {
    var regexp = new RegExp('<[^>]+>', 'g')
    var str = st.replace(regexp, '')
    if (str !== null && str !== undefined) {
      if (str.length <= 3) {
        return '*' + str.substring(1, str.length)
      } else if (str.length > 3 && str.length <= 6) {
        return '**' + str.substring(2, str.length)
      } else if (str.length <= 10 && str.length > 6) {
        var s = ''
        for (var num = 0; num < str.length; num++) {
          s += '*'
        }
        return str.substring(0, 2) + s + str.substring(str.length - 2, str.length)
      } else if (str.length > 10) {
        return str.substring(0, 2) + '******' + str.substring(str.length - 2, str.length)
      }
    } else {
      return ''
    }
    // 公司脱敏
  },
  peopleName: function (st) {
    var regexp = new RegExp('<[^>]+>', 'g')
    var str = st.replace(regexp, '')
    if (str !== null && str !== undefined) {
      if (str.length <= 6) {
        var n = ''
        for (var num = 1; num < str.length; ++num) {
          n += '*'
        }
        return str.substring(0, 1) + n
      }
    } else {
      return ''
    }
    // 公司脱敏
  },
  phoneName: function (st) {
    var regexp = new RegExp('<[^>]+>', 'g')
    var str = st.replace(regexp, '')
    if (str !== null && str !== undefined) {
      if (str.length === 11) {
        var n = ''
        for (var num = 1; num < 5; ++num) {
          n += '*'
        }
        return str.substring(0, 3) + n + str.substring(7, 11)
      }
    } else {
      return ''
    }
    // 公司脱敏
  }
}
var filt = {
  toFix: function (value) {
    return value.toFixed(1)
  }
}
var Str = {
  toStr: function (value) {
    return value.split('#')[0]
  }
}

module.exports = {
  todate: filters.todate,
  getTwo: filters.getTwo,
  getcompanyName: filters.getcompanyName,
  getNumber: filters.getNumber,
  toFixs: filt.toFix,
  toStr: Str.toStr,
  replaceStr: filters.replaceStr,
  peopleName: filters.peopleName,
  phoneName: filters.phoneName
}
